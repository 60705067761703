import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-property-message',
  templateUrl: './my-property-message.component.html',
  styleUrls: ['./my-property-message.component.css']
})
export class MyPropertyMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
