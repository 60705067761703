import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MainLayoutModule } from './main-layout/main-layout.module';
import { HttpService } from './shared/services/http/http-service.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ConfigService } from './shared/services/config/config.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PropertyLoanViewComponent } from './property-loan-view/property-loan-view.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ColorPickerModule } from 'ngx-color-picker';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { LocationService } from './services/location.service';
import { AssignContactsComponent } from './assign-contacts/assign-contacts.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { UserComponent } from './user/user.component';
import { ViewRegistertedComponent } from './view-registerted/view-registerted.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { AssignPropertyComponent } from './assign-property/assign-property.component';
import { MyPromocodesComponent } from './my-promocodes/my-promocodes.component';
import { MyAssignedpropertyComponent } from './my-assignedproperty/my-assignedproperty.component';
import { DashboardDatatableComponent } from './dashboard/dashboard-datatable/dashboard-datatable.component';
import { MyProfessionalComponent } from './my-professional/my-professional.component';

@NgModule({
  declarations: [													
    AppComponent,
    LoginComponent,
    DashboardComponent,
    PropertyLoanViewComponent,
      SpinnerComponent,
      AssignContactsComponent,
      ContactListComponent,
      UserComponent,
      ViewRegistertedComponent,
      PropertyListComponent,
      AssignPropertyComponent,
      MyPromocodesComponent,
      MyAssignedpropertyComponent,
      DashboardDatatableComponent,
      MyProfessionalComponent
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatTableModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MainLayoutModule,
    AppRoutingModule,
    MatMenuModule,
    MatButtonModule,
    HttpClientModule,
    MatTableModule,
    ColorPickerModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true
    },
    HttpService, ConfigService,LocationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
