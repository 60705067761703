import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { StorageService } from "src/app/services/storage-service";
import { ConfigService } from "../config/config.service";




@Injectable()
export class HttpService {
  [x: string]: any;

  private headers: HttpHeaders;

  constructor(private httpClient: HttpClient, private storageService: StorageService,
    private configService: ConfigService) {
    this.headers = new HttpHeaders()
  }

  public postJson(path: string, payload: any): Observable<any> {

    this.headers.append('Content-Type', 'application/json');
    const url = this.configService.getApiBaseUrl() + '/' + path;
    return this.httpClient.post<any>(url, payload, { headers: this.headers });

  }

  public get(path: string): Observable<any> {
    const url = this.configService.getApiBaseUrl() + '/' + path;
    return this.httpClient.get<any>(url, { headers: this.headers });
  }

  public postFormData(path: string, formData: FormData): Observable<any> {
    this.headers.append('Content-Type', 'multipart/form-data');
    const url = this.configService.getApiBaseUrl() + '/' + path;
    return this.httpClient.post<any>(url, formData, { headers: this.headers });
  }
  public putFormData(path: string, formData: FormData): Observable<any> {
    this.headers.append('Content-Type', 'multipart/form-data');
    const url = this.configService.getApiBaseUrl() + '/' + path;
    return this.httpClient.put<any>(url, formData, { headers: this.headers });
  }

  public put(path: string, payload: any) {
    const url = this.configService.getApiBaseUrl() + '/' + path;
    return this.httpClient.put(url, payload, { headers: this.headers });
  }

  public delete(path: string, payload: any) {
    const url = this.configService.getApiBaseUrl() + '/' + path;
    return this.httpClient.delete(url, payload);
  }

  public getPaginationData(path: any, page: any = 0, pageSize: any = 10, deedType?: any, code?:  any, id?: any,search?:any,assignedByAdmin?:any,status?:any,verified?:any) {
    if (!page) {
      page = 1;
    }
    let conditionOption = "?page=" + page + "&pageSize=" + pageSize;
    if (deedType) {
      conditionOption = conditionOption + "&deedType=" + deedType;
    }
    if (code) {
      conditionOption = conditionOption + "&code=" + code;
    }
    if (id) {
      conditionOption = conditionOption + "&id=" + id;
    }
  
    if (search) {
      conditionOption = conditionOption + "&search=" + search;
    }
    if (assignedByAdmin) {
      conditionOption = conditionOption + "&assignedByAdmin=" + assignedByAdmin;
    }
    if (status) {
      conditionOption = conditionOption + "&status=" + status;
    }
    if (verified) {
      conditionOption = conditionOption + "&verified=" + verified;
    }
    const url = this.configService.getApiBaseUrl() + '/' + path + conditionOption;
    return this.httpClient.get<any>(url, { headers: this.headers });
  }
  public getProfessionalData(path: any, page: any = 0, pageSize: any = 10,  id?: any,search?:any) {
    if (!page) {
      page = 1;
    }
    let conditionOption = "?page=" + page + "&pageSize=" + pageSize;
 
    if (id) {
      conditionOption = conditionOption + "&id=" + id;
    }
  
    if (search) {
      conditionOption = conditionOption + "&name=" + search;
    }
   
  
    const url = this.configService.getApiBaseUrl() + '/' + path + conditionOption;
    return this.httpClient.get<any>(url, { headers: this.headers });
  }
  public searchLocationData(path:any,keyWord:any){
      const url = this.configService.getApiBaseUrl() + '/' + path + '?keyword=' + keyWord;
      return this.httpClient.get<any>(url, { headers: this.headers });

  }
  public getPaginationRegisterData(path: any, page: any = 0, pageSize: any = 10, roleCode?: any, code?:  any,search?:any,) {
    if (!page) {
      page = 1;
    }
    let conditionOption = "?page=" + page + "&pageSize=" + pageSize;
    if (roleCode) {
      conditionOption = conditionOption + "&roleCode=" + roleCode;
    }
    if (code) {
      conditionOption = conditionOption + "&code=" + code;
    }
   
    // if (isAssigned) {
    //   conditionOption = conditionOption + "&isAssigned=" + isAssigned;
    // }
    if (search) {
      conditionOption = conditionOption + "&search=" + search;
    }
   
    const url = this.configService.getApiBaseUrl() + '/' + path + conditionOption;
    return this.httpClient.get<any>(url, { headers: this.headers });
  }

  public getPaginationLocationData(path: any, page: any = 0, pageSize: any = 10,search?:any,) {
    if (!page) {
      page = 1;
    }
    let conditionOption = "?page=" + page + "&pageSize=" + pageSize;
   
    if (search) {
      conditionOption = conditionOption + "&search=" + search;
    }
   
    const url = this.configService.getApiBaseUrl() + '/' + path + conditionOption;
    return this.httpClient.get<any>(url, { headers: this.headers });
  }
  public getPaginationTagData(path: any, page: any = 0, pageSize: any = 10,tagType?:any,tagName?:any) {
    if (!page) {
      page = 1;
    }
    let conditionOption = "?page=" + page + "&pageSize=" + pageSize;
   
    if (tagType) {
      conditionOption = conditionOption + "&tagType=" + tagType;
    }
    if (tagName) {
      conditionOption = conditionOption + "&tagName=" + tagName;
    }
   
    const url = this.configService.getApiBaseUrl() + '/' + path + conditionOption;
    return this.httpClient.get<any>(url, { headers: this.headers });
  }
  public getCustomPlanData(path: any, page: any = 0, pageSize: any = 10,id?:any,searchTerm?:any) {
    if (!page) {
      page = 1;
    }
    let conditionOption = "?page=" + page + "&pageSize=" + pageSize;
   
    if (id) {
      conditionOption = conditionOption + "&id=" + id;
    }
    if (searchTerm) {
      conditionOption = conditionOption + "&searchTerm=" + searchTerm;
    }


    const url = this.configService.getApiBaseUrl() + '/' + path + conditionOption;
    return this.httpClient.get<any>(url, { headers: this.headers });
  }
  
  public getLocalJsonData(jsonFileName: any): Observable<any> {
    return this.httpClient.get("./assets/" + jsonFileName, { headers: this.headers });
  }


  public post(path: string, formData: FormData): Observable<any> {
    const url = this.configService.getApiBaseUrl() + '/' + path;
    return this.httpClient.post<any>(url, formData, { headers: this.headers });
  }
}

