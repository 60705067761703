<div class="container">
  <div class="card-container">
    <div *ngFor="let element of dataSource.data" class="card">
      <div class="card-content">
        <div class="card-key">
          <!-- Key text -->
          <span>{{ element.key }}:</span>
          <!-- Image on the right -->
          <img *ngIf="element.key === 'Property Count'" class="card-image" [src]="element.imageUrl ? element.imageUrl : 'assets/property.jpeg'" alt="{{ element.key }}" />
          <img *ngIf="element.key === 'User Count'" class="card-image" [src]="element.imageUrl ? element.imageUrl : 'assets/user.png'" alt="{{ element.key }}" />
          <img *ngIf="element.key === 'Professional Page Count'" class="card-image" [src]="element.imageUrl ? element.imageUrl : 'assets/male.png'" alt="{{ element.key }}" />
          <img *ngIf="element.key === 'Post Count'" class="card-image" [src]="element.imageUrl ? element.imageUrl : 'assets/camera1.png'" alt="{{ element.key }}" />
        </div>

        <div class="card-value">{{ element.value }}</div>
      </div>
    </div>
    <div class="no-records-text" *ngIf="!dataSource || dataSource.data.length <= 0">
      No Records Found!
    </div>
  </div>
</div>








