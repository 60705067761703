<mat-nav-list class="menu-size">
  <div class="logo">
    <img src="../../assets/Veedu-App-Web-Logo (1).png" class="img" alt="Company Logo" />
  </div>
  <div>
    <ng-container
      *ngFor="let menu of menus"
      [ngTemplateOutlet]="subMenuNode"
      [ngTemplateOutletContext]="{ $implicit: menu }">
    </ng-container>
  </div>
  <ng-template #subMenuNode let-menu>
    <a href="javascript:void(0)" *ngIf="!menu.subMenus || menu.subMenus.length == 0" mat-list-item  [ngClass]="menu.active == true ? 'list-item-active' : ''"
    (click)="routeMenu(menu)" >
      <mat-icon>{{menu.icon}}</mat-icon> {{menu.name}}
    </a>
    <mat-list-item [ngClass]="menu.active == true ? 'list-item-active' : ''" *ngIf="menu.subMenus && menu.subMenus.length > 0" (click)="showHideSubMenu(menu)">
      <mat-icon>{{menu.icon}}</mat-icon>{{menu.name}}<span style="margin-left: 50px;" *ngIf="menu.subMenus && menu.subMenus.length > 0"><mat-icon *ngIf="!menu.showSubMenu">chevron_right</mat-icon><mat-icon *ngIf="menu.showSubMenu">expand_more</mat-icon></span>
    </mat-list-item>
    <ng-container *ngIf="menu.subMenus && menu.showSubMenu ">
      <ul class="subMenu">
        <ng-container
          *ngFor="let subMenu of menu.subMenus"
          [ngTemplateOutlet]="subMenuNode"
          [ngTemplateOutletContext]="{ $implicit: subMenu }">
        </ng-container>
      </ul>
    </ng-container>
  </ng-template>
</mat-nav-list>