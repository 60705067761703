<section class="vh-100">
      <div class="container">
        <div class="image">
          <img src="../../assets/Veedu-App-Web-Logo (1).png"
            class="img" alt="Sample image">
        </div>
        <div class="form">
            <!-- user name input -->
            <div class="form-outline mb-4"> 
              <input placeholder="Enter Mobile Number" type="number" [(ngModel)] ="username" required="true"  class="form-control form-control-lg" />
              <!-- <label class="form-label" >User Name</label> -->
            </div>
  
            <!-- Password input -->
            <div class="form-outline mb-3">
              <input placeholder="Password" type="password" [(ngModel)]="password" class="form-control form-control-lg"/>
              <!-- <label class="form-label" >Password</label> -->
            </div>
            <div  class="form-outline mb-3" *ngIf="roleCode != 'ROLE_SUPER_ADMIN'"> 
              <mat-form-field>
                <mat-label>User Type</mat-label>
                <mat-select id="User Type" [(ngModel)] = "roleCode" >
                    <mat-option *ngFor= "let roleCode of roleDataList" [value]= "roleCode.roleCode"> {{roleCode.roleName}}</mat-option>
                </mat-select>
            </mat-form-field>
              <!-- <mat-form-field>
                <mat-label>User Type</mat-label>
                <mat-select [(value)]="roleCode">
                  <mat-option value="ROLE_PRO_USER">Professional </mat-option>
                  <mat-option value="ROLE_VIEW_USER">Regular</mat-option>
                  <mat-option value="ROLE_TELE_CALLER">TeleCaller</mat-option>
                </mat-select>
              </mat-form-field> -->
          </div>  
            <div class="text-center text-lg-start mt-4 pt-2">
              <button class= "btn1" (window:keypress)="onSubmit($event)" (click)="onSubmit('Enter')" >Login</button>
            </div>
            <app-spinner></app-spinner>  
        </div>
      </div>
   
  
  </section>
  
