<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-9 mt-3">
      <div id="sign-up" class="modal-content">
        <div class="modal-body">
          <h2 class="text-center">Register</h2>
          <br />
          <div class="row">
            <div class="col-lg-6 col-md-6 form-group">
              <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="firstName" placeholder="First Name">
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6">
              <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="lastName" placeholder="Last Name">
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6">
              <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="email" placeholder="Email">
              </mat-form-field>
            </div>
            <div class="form-outline col-lg-6 col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>User Type</mat-label>
                <mat-select [(value)]="roleCode">
                  <mat-option value="ROLE_PRO_USER">Professional </mat-option>
                  <mat-option value="ROLE_VIEW_USER">Regular</mat-option>
                  <mat-option value="ROLE_TELE_CALLER">TeleCaller</mat-option>
                  <mat-option value="ROLE_PROPERTY_SUPPORT">PropertySupport</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-6">
              <mat-form-field class="example-full-width">
                <input matInput type="password" [(ngModel)]="password" placeholder="Password">

              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6">
              <mat-form-field class="example-full-width">
                <input matInput type="password" [(ngModel)]="confirmPassword" placeholder="Confirm Password">
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6">
              <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="mobile" type ="number" placeholder="Mobile number" (keypress)="validateNumber($event, 10)">
              </mat-form-field>
            </div>
            
           
            <div class="col-lg-6 col-md-6">
              <mat-form-field  class="example-full-width">
                <mat-label>MembershipType</mat-label>
                <mat-select required  [(ngModel)]="membershipBenefitMapperId" (ngModelChange)="handleProductChange($event)">
                  <mat-option *ngFor="let membershipBenefitMappers of membershipBenefitMappersDataList" [value]="membershipBenefitMappers">{{
                    membershipBenefitMappers.memberShipTypeLabel }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div >
              <mat-form-field class="example-full-width">
                <mat-label>Search Location</mat-label>
                <input type="text" placeholder="Location" aria-label="Location" matInput [(ngModel)]="location"
                  [formControl]="myControl" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayLocation">
                  <mat-option *ngFor="let location of filteredOptions | async" [value]="location">
                    {{ displayLocation(location) }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

            </div>
            <div > Have any PromoCode?apply </div>
            <div class="col-lg-6 col-md-6">
              <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="promoCode" placeholder="PromoCode">
              </mat-form-field>
            </div>  
            <div>
              <button class="btn btn-primary ms-2" (click)="verifyCode()">Verify</button>
             </div>
          </div>
          <label *ngIf= "amount " for="">discountamount = {{amount}}</label>
          <div class="row justify-content-center my-3">
            <button type="button" class="btn btn-theme-light-2 " (click)="onSubmit()">
              Register
            </button>
           
          </div>
        </div>
      </div>
    </div>
  </div>