import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../shared/services/snackbar/snackbar.service';
import { HttpService } from '../shared/services/http/http-service.service';
import { StorageService } from '../services/storage-service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public username : any;
  public password : any;
  public roleCode : any;
  public loginValid = false;
  user: any;
  public path:any;
  public roleDataList:any[]=[];
  pathFromParams: any;
  public authToken:any;
  constructor(private router:Router,
    private httpService: HttpService,private storageService: StorageService,
    public snackbarService: SnackbarService,private route: ActivatedRoute) {   
      this.authToken = this.route.snapshot.queryParams['token'];   
     }

  ngOnInit(): void {
    this.getAllRoles();
    this.user = this.storageService.getUserData();
    if(this.authToken != null){
      this.doProxyLogin();
    }
    if(this.user){
      this.storageService.clear();
      // this.router.navigate(['/main']);
    }
      this.getRoleCode();
    
  }

  public doProxyLogin (){
    let url = "?token=" + this.authToken;
    this.httpService.get('auth/proxyLogin/'+url).subscribe(res => {
      this.snackbarService.showSuccessMessage("Successfully LoggedIn");
      this.storageService.saveUserData(res);
      this.router.navigate(['/main']);
      return ;   
    },(err => {
      console.log(err);
      this.snackbarService.showErrorMessage("Invalid User or Password");
    }))


  }
  public getRoleCode(){
    this.path = this.router.url;
    if(this.path == '/super_admin'){
      this.roleCode = 'ROLE_SUPER_ADMIN';
      return;
    }
    if(this.path == '/tele_caller'){
      this.roleCode = 'ROLE_TELE_CALLER';
      return;
    }

  }

  public getAllRoles() {
    this.httpService.get('persons/roles').subscribe(res => {
      this.roleDataList = res;
      console.log('this.roleDataList',this.roleDataList);
      this.setRequiredRoleData();
      return ;   
    },(err => {
      console.log(err);
    }))
  }

  public setRequiredRoleData(){
    this.roleDataList.forEach((element,index) => {
      if(element.roleCode === 'ROLE_SUPER_ADMIN' ){
        this.roleDataList.splice(index,1);        
      }

    });
    console.log('this.roleDataList',this.roleDataList);
  }
public onSubmit(event:any){
  if(event.key == 'Enter' || event == 'Enter'){
    if(!this.username){
      this.snackbarService.showSuccessMessage("Enter Mobile Number");
      return;
    }
    if(!this.password){
      this.snackbarService.showSuccessMessage("Enter Password");
      return;
    }
    let payload :any = {};
        payload['mobile'] = this.username;
        payload['password'] = this.password;
        payload['roleCode'] = this.roleCode;      
        this.httpService.postJson('auth', payload).subscribe(res => {
          this.snackbarService.showSuccessMessage("Successfully LoggedIn");
          this.storageService.saveUserData(res);
          this.router.navigate(['/main']);
          return ;   
        },(err => {
          console.log(err);
          this.snackbarService.showErrorMessage("Invalid User or Password");
        }))
  }
  
}
}
