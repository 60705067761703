import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, startWith, map } from 'rxjs';
import { StorageService } from 'src/app/services/storage-service';
import { HttpService } from 'src/app/shared/services/http/http-service.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public firstName: any;
  public lastName: any;
  public email: any;
  public password: any;
  public mobile: any;
  public promoCode: any;
  public confirmPassword: any;
  public roleCode: any;
  public location: any;
  public file: any;
  public currentPage = 0;
  public totalSize: number = 0;
  public isRefreshing = false;
  public searchTerm: any;
  public searchBy: any;
  public search: any;
  public pageSize = 100;
  public selectedProducts: any[] = [];
  public membershipBenefitMappersDataList: any[] = [];
  public membershipBenefitMapperId: any;
  public amount: any;



  public locationDataList: any[] = [];
  user: any;
  myControl = new FormControl('');
  filteredOptions!: Observable<any[]>;

  constructor(private router: Router,
    private httpService: HttpService, private storageService: StorageService,
    public snackbarService: SnackbarService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getMemberShipTypeData()
    this.user = this.storageService.getUserData();
    this.getLocationDataList();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      map(value => this.filterLocations(value || ''))
    );
  }
  verifyCode() {
    this.httpService.get('promoCodes/code/' + this.promoCode).subscribe(res => {
      this.amount = res.discountAmount;

    }, (err => {
      console.log(err);
      this.snackbarService.showSuccessMessage(err);



    }))
  }

  getLocationDataList = () => {
    this.httpService.getPaginationLocationData('locations/flat', this.currentPage, this.pageSize, this.search).subscribe(res => {
      this.locationDataList = res.data;
    })
  }

  displayLocation(location: any): string {
    if (location) {
      return `${location.name}|${location.pinCode}|${location.postOfficeName}|${location.districtName}|${location.stateName} `;
    }
    return '';
  }


  private filterLocations(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.locationDataList.filter(location =>
      location.name.toLowerCase().includes(filterValue)
    );
  }
  handleProductChange(event: any) {
    console.log('Selected value(s):', event);
    this.selectedProducts = event;
  }
  getMemberShipTypeData = () => {
    this.httpService.get('persons/templates').subscribe(res => {
      this.membershipBenefitMappersDataList = res.membershipBenefitMappers;

    })
  }
  getAllSelectedProducts = () => {
    let selectedProduct: any[] = [];
    for (let i = 0; i < this.selectedProducts.length; i++) {
      if (this.selectedProducts[i].id) {
        selectedProduct.push({ id: this.selectedProducts[i].id });
      }
    }
    return JSON.stringify(selectedProduct);
  };

  public onSubmit() {

    if (!this.firstName) {
      this.snackbarService.showSuccessMessage("Enter First Name");
      return;
    }
    if (!this.lastName) {
      this.snackbarService.showSuccessMessage("Enter Last Name");
      return;
    }
    if (!this.email) {
      this.snackbarService.showSuccessMessage("Enter Email ");
      return;
    }
    if (!this.roleCode) {
      this.snackbarService.showSuccessMessage("Select  User Type ");
      return;
    }
    if (!this.password) {
      this.snackbarService.showSuccessMessage("Enter Password");
      return;
    }
    if (!this.mobile) {
      this.snackbarService.showSuccessMessage("Enter Mobile No.");
      return;
    }
    if (this.password != this.confirmPassword) {
      this.snackbarService.showSuccessMessage("Password does not match");
      return;
    }
    if (!this.location) {
      this.snackbarService.showSuccessMessage("Enter Location");
      return;
    }
    this.saveContact();
    let payload: any = {};
    payload['firstName'] = this.firstName;
    payload['lastName'] = this.lastName;
    payload['email'] = this.email;
    payload['mobile'] = this.mobile;
    payload['password'] = this.password;
    payload['userType'] = this.roleCode;
    payload['locationId'] = this.location.id;
    payload['membershipBenefitMapperId'] = this.membershipBenefitMapperId.id;
    payload['promoCode'] = this.promoCode;


    this.httpService.postJson('persons/createUser', payload).subscribe(res => {
      this.snackbarService.showSuccessMessage("Successfully Registered");
      this.router.navigate(['/main/viewregisterted']);
      return;
    })
  }

  limitText(size: number, value: any): boolean {
    if (value.length >= size) {
      return false;
    } else {
      return true;
    }
  }

  public validateNumber = ($event: any, limit?: number): boolean => {
    const charCode = ($event.which) ? $event.which : $event.charCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    if (limit) {
      return this.limitText(limit, $event.target.value);
    }
    return true;
  }



  saveContact = () => {
    let formData = this.validateAndContructPayload();
    this.httpService.postFormData('contacts', formData).subscribe(res => {
    })
  }

  validateAndContructPayload() {
    const formData = new FormData();
    formData.append('firstName', this.firstName);
    formData.append('address', "");
    formData.append('webAddress', "");
    formData.append('companyName', "");
    formData.append('latitude', "");
    formData.append('longitude', "");
    formData.append('lastName', this.lastName);
    formData.append('email', this.email);
    formData.append('whatsAppNumber', this.mobile);
    formData.append('countryCode', "");
    formData.append('ageIdStr', "");
    formData.append('genderType', "");
    if (this.file) {
      formData.append('profileImageURL', "");
    }
    formData.append('locationIdStr', this.location.id);
    formData.append('professionIdStr', "");
    formData.append('contactEntryTypeCode', "FROM_ADMIN_REGISTER");

    return formData;
  }

}

