import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/shared/services/http/http-service.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-dashboard-datatable',
  templateUrl: './dashboard-datatable.component.html',
  styleUrls: ['./dashboard-datatable.component.css']
})
export class DashboardDatatableComponent implements OnInit {

  public displayedColumns: string[] = ['key','value'];
  public dataSource = new MatTableDataSource<any>();
  public footerType :any;
  public footerTypelabel :any;
  public userCount:any;

  constructor(private router: Router, private httpService: HttpService,
    private cdr: ChangeDetectorRef, public snackbarService: SnackbarService, public route: ActivatedRoute) { 
  
    }
   

  ngOnInit(): void {
    this.getFooterData();
  }

 
  getFooterData = () => {
    this.httpService.get('dashboard/count').subscribe(res => {
      console.log('resssssssssssssssss==========>',res);
      this.refreshDataTable(res);

    })
  }

 

  

 
  private refreshDataTable = (res: any) => {
    const formattedData = [
      { key: 'User Count', value: res.userCount },
      { key: 'Professional Page Count', value: res.professionalPageCount },
      { key: 'Post Count', value: res.postCount },
      { key: 'Property Count', value: res.propertyCount },
    ];

    this.dataSource.data = formattedData;
    this.dataSource._updateChangeSubscription();

    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

}
