import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/services/storage-service';

@Injectable({
  providedIn: 'root'
})
export class SideNavMenuService {

  public menus: any[] = [];

  constructor(private storageService: StorageService) {
    this.menus = [
      {
        "name": 'Home',
        "code": "home",
        "active": true,
        "link": "/main/home",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN', 'ROLE_PRO_USER', 'ROLE_TELE_CALLER', 'ROLE_PROPERTY_SUPPORT'],
        "icon": "home"
      },
      {
        "name": 'My Profile',
        "code": "myProfile",
        "active": true,
        "link": "/main/myProfile",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN', 'ROLE_PRO_USER', 'ROLE_TELE_CALLER', 'ROLE_PROPERTY_SUPPORT'],
        "icon": "account_circle"
      },
      {
        "name": 'Dashboard',
        "code": "dashboard",
        "active": false,
        "link": "/main/dashboard",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN', 'ROLE_PRO_USER', 'ROLE_TELE_CALLER', 'ROLE_PROPERTY_SUPPORT'],
        "icon": "dashboard"
      },
      {
        "name": 'MemberShip',
        "code": "benefit",
        "active": false,
        "link": "/main/benefit",
        "icon": "card_giftcard",
        "showSubMenu": false,
        "roles": ['ROLE_SUPER_ADMIN'],
        "subMenus": [

          {
            "name": 'MemberShip Type',
            "code": "memberShipType",
            "active": false,
            "link": "main/benefit/memberShipType",
            "icon": "card_giftcard"
          },


          {
            "name": 'MemberShipBenefit',
            "code": "memberShipbenefit",
            "active": false,
            "link": "main/benefit/memberShipBenefit",
            "icon": "card_giftcard"
          },


          {
            "name": 'MemberShip Faq',
            "code": "memberShipFaq",
            "active": false,
            "link": "/main/benefit/memberShipFaq",
            "icon": "contact_support",
            "showSubMenu": false,
            "subMenus": [
              {
                "name": 'Free Faq',
                "memberShipType": 'FREE',
                "code": "free",
                "active": false,
                "link": "/main/benefit/memberShipFaq",
                "icon": "contact_support"
              },
              {
                "name": 'Monthly Faq',
                "memberShipType": 'MONTHLY',
                "code": "monthly",
                "active": false,
                "link": "/main/benefit/memberShipFaq",
                "icon": "contact_support"
              },
              {
                "name": 'Annual Faq',
                "memberShipType": 'ANNUAL',
                "code": "annual",
                "active": false,
                "link": "/main/benefit/memberShipFaq",
                "icon": "contact_support"
              }
            ]
          },

        ]
      },
      {
        "name": 'Membeship Plan',
        "code": "membeship_plan",
        "active": true,
        "link": "/main/membeship-plan",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "app_registration"
      },
      {
        "name": 'Marketing',
        "code": "marketing",
        "active": false,
        "link": "/main/marketing",
        "icon": "card_giftcard",
        "showSubMenu": false,
        "roles": ['ROLE_SUPER_ADMIN'],
        "subMenus": [


          {
            "name": 'Code Type',
            "code": "codeType",
            "active": false,
            "link": "main/benefit/codeType",
            "icon": " card_membership "
          },
          {
            "name": 'Discount Type',
            "code": "discountType",
            "active": false,
            "link": "main/benefit/discountType",
            "icon": " card_membership "
          },

          {
            "name": 'PromoCode',
            "code": "promoCode",
            "active": false,
            "link": "main/benefit/promoCode",
            "icon": " card_membership "
          },
          {
            "name": 'PromoCodeAssign',
            "code": "promoCodeAssign",
            "active": false,
            "link": "main/benefit/promoCodeAssign",
            "icon": " card_membership "
          },

        ]
      },
      {
        "name": 'FloorPlan',
        "code": "floorplan",
        "active": false,
        "link": "/main/floorplan",
        "icon": "card_giftcard",
        "showSubMenu": false,
        "roles": ['ROLE_SUPER_ADMIN'],
        "subMenus": [


          {
            "name": 'Add Plan',
            "code": "addPlan",
            "active": false,
            "link": "main/benefit/addPlan",
            "icon": "store"
          },


          {
            "name": 'Plan Setting',
            "code": "CustomerPlan",
            "active": false,
            "link": "/main/customerPlan",
            "icon": "store"
          },
          {
            "name": 'Terms and Condition',
            "code": "TermsAndCondition",
            "active": false,
            "link": "/main/termsAndCondition",
            "icon": "note"
          },
          {
            "name": 'CustomerPlanRequest',
            "code": "CustomerPlanRequest",
            "active": false,
            "link": "/main/customerPlanRequest",
            "icon": "store"
          },

        ]
      },
      {
        "name": 'Contact Details',
        "code": "contactDetails",
        "active": false,
        "link": "/main/contactDetails",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN', 'ROLE_PRO_USER', 'ROLE_TELE_CALLER'],
        "icon": "contacts"
      },
      {
        "name": 'Assigned Contacts',
        "code": "assignContacts",
        "active": false,
        "link": "/main/assignContacts",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN', 'ROLE_PRO_USER', 'ROLE_TELE_CALLER'],
        "icon": "contacts"
      },
      {
        "name": 'Contact List',
        "code": "contactList",
        "active": false,
        "link": "/main/contactList",
        "subMenus": [
          {
            "name": 'AllContacts',
            "code": "allContacts",
            "active": false,
            "link": "/main/contactList",
            "icon": "contacts"
          },
          {
            "name": 'RegistertedContacts',
            "code": "registertedContacts",
            "active": false,
            "link": "/main/contactList/registertedContact",
            "icon": "contacts"
          },
          {
            "name": 'LoanContacts',
            "code": "loanContacts",
            "active": false,
            "link": "/main/contactList/loanContact",
            "icon": "contacts"
          },
          {
            "name": 'Label',
            "code": "label",
            "active": false,
            "link": "/main/label",
            "icon": "work_outline"
          },

        ],
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "view_comfy"
      },

      {
        "name": 'Property List',
        "code": "propertyList",
        "active": false,
        "link": "/main/propertyList",
        "subMenus": [
          {
            "name": 'AllProperty',
            "code": "allProperty",
            "active": false,
            "link": "/main/propertyList",
            "icon": "apartments"

          },

          // {
          //   "name": 'MyProperty',
          //   "code": "myProperty",
          //   "active": false,
          //   "link": "/main/propertyList/myProperty",
          //   "icon": "apartments"
          // },

        ],
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "apartments"
      },

      {
        "name": 'MyPromoCodes',
        "code": "myPromoCodes",
        "active": false,
        "link": "/main/myPromoCodes",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN', 'ROLE_PRO_USER', 'ROLE_VIEW_USER', 'ROLE_TELE_CALLER', 'ROLE_PROPERTY_SUPPORT'],
        "icon": "apartments"
      },
      {
        "name": 'MyAssignedProperty',
        "code": "myassignedProperty",
        "active": false,
        "link": "/main/myassignedProperty",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN', 'ROLE_PRO_USER', 'ROLE_VIEW_USER', 'ROLE_TELE_CALLER', 'ROLE_PROPERTY_SUPPORT'],
        "icon": "apartments"
      },
      {
        "name": 'MyProfessional',
        "code": "myProfessional",
        "active": false,
        "link": "/main/myProfessional",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN', 'ROLE_PRO_USER', 'ROLE_VIEW_USER', 'ROLE_TELE_CALLER', 'ROLE_PROPERTY_SUPPORT'],
        "icon": "person"
      },
      {
        "name": 'MyProperty',
        "code": "myProperty",
        "active": false,
        "link": "/main/myProperty",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN', 'ROLE_PRO_USER', 'ROLE_VIEW_USER', 'ROLE_TELE_CALLER', 'ROLE_PROPERTY_SUPPORT'],
        "icon": "apartments"
      },
      {
        "name": 'MyPropertyMessages',
        "code": "myPropertyMessage",
        "active": false,
        "link": "/main/myPropertyMessage",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN', 'ROLE_PRO_USER', 'ROLE_VIEW_USER', 'ROLE_TELE_CALLER', 'ROLE_PROPERTY_SUPPORT'],
        "icon": "message"
      },
      
      {
        "name": 'Assigned Property',
        "code": "assignProperty",
        "active": false,
        "link": "/main/assignProperty",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "apartments"
      },
      {
        "name": 'User',
        "code": "user",
        "active": false,
        "link": "/main/user",
        "subMenus": [
          {
            "name": 'RegularUser',
            "code": "regularUser",
            "active": false,
            "link": "/main/user",
            "icon": "app_registration"
          },
          {
            "name": 'ProUser',
            "code": "proUser",
            "active": false,
            "link": "/main/user/proUser",
            "icon": "app_registration"
          },

        ],
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "view_comfy"
      },

      {
        "name": 'Loan View',
        "code": "propertyLoanView",
        "active": false,
        "link": "/main/propertyLoanView",
        "subMenus": [
          {
            "name": 'AllLoans',
            "code": "allLoans",
            "active": false,
            "link": "/main/propertyLoanView",
            "icon": "view_comfy"
          },
          {
            "name": 'AssignedLoans',
            "code": "assignedLoans",
            "active": false,
            "link": "/main/propertyLoanView/assignedLoan",
            "icon": "view_comfy"
          },
          {
            "name": 'UnAssignedLoans',
            "code": "unAssignedLoans",
            "active": false,
            "link": "/main/propertyLoanView/unAssignedLoan",
            "icon": "view_comfy"
          },
          {
            "name": 'DeletedLoans',
            "code": "deletedLoans",
            "active": false,
            "link": "/main/propertyLoanView/deletedLoan",
            "icon": "deleted"
          },
        ],
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "view_comfy"
      },
      {
        "name": 'Task',
        "code": "task",
        "active": false,
        "link": "/main/task",
        "subMenus": [],
        "roles": ['ROLE_TELE_CALLER'],
        "icon": "view_comfy"
      },
      {
        "name": 'Task',
        "code": "messageTask",
        "active": false,
        "link": "/main/messageTask",
        "subMenus": [],
        "roles": ['ROLE_PROPERTY_SUPPORT'],
        "icon": "view_comfy"
      },
      {
        "name": 'Location',
        "code": "location",
        "active": false,
        "link": "/main",
        "icon": "place",
        "showSubMenu": false,
        "roles": ['ROLE_SUPER_ADMIN'],
        "subMenus": [
          {
            "name": 'Country',
            "code": "country",
            "active": false,
            "link": "/main/country",
            "icon": "language"
          },
          {
            "name": 'State',
            "code": "state",
            "active": false,
            "link": "/main/state",
            "icon": "outlined_flag"
          },
          {
            "name": 'District',
            "code": "district",
            "active": false,
            "link": "/main/district",
            "icon": "person_pin_circle"
          },
          {
            "name": 'Taluka',
            "code": "tauka",
            "active": false,
            "link": "/main/taluka",
            "icon": "home"
          },
          {
            "name": 'PostOffice',
            "code": "postoffice",
            "active": false,
            "link": "/main/postoffice",
            "icon": "local_post_office"
          },
          {
            "name": 'Pin Code',
            "code": "pincode",
            "active": false,
            "link": "/main/pincode",
            "icon": "pin_drop"
          },
          {
            "name": 'Location Priority',
            "code": "locationPriority",
            "active": false,
            "link": "/main/locationPriority",
            "icon": "place"
          },
          {
            "name": 'Location',
            "code": "location",
            "active": false,
            "link": "/main/location",
            "icon": "place"
          },
          {
            "name": 'DeletedLocation',
            "code": "deletedLocation",
            "active": false,
            "link": "/main/deletedLocation",
            "icon": "place"
          },
          {
            "name": 'PopularTown',
            "code": "popularTown",
            "active": false,
            "link": "/main/popularTown",
            "icon": "commute"
          },
          {
            "name": 'Import Location',
            "code": "bulkImport",
            "active": false,
            "link": "/main/location-bulk-import",
            "icon": "commute",
            "showSubMenu": false,
            "subMenus": [
              {
                "name": 'Country',
                "code": "countryBulk",
                "active": false,
                "link": "/main/location-bulk-import/add-country-bulk",
                "icon": "language"
              },
              {
                "name": 'State',
                "code": "stateBulk",
                "active": false,
                "link": "/main/location-bulk-import/add-state-bulk",
                "icon": "outlined_flag"
              },
              {
                "name": 'District',
                "code": "districtBulk",
                "active": false,
                "link": "/main/location-bulk-import/add-district-bulk",
                "icon": "person_pin_circle"
              },
              {
                "name": 'Taluk',
                "code": "talukBulk",
                "active": false,
                "link": "/main/location-bulk-import/add-taluk-bulk",
                "icon": "home"
              },
              {
                "name": 'PostOffice',
                "code": "postofficeBulk",
                "active": false,
                "link": "/main/location-bulk-import/add-postoffice-bulk",
                "icon": "local_post_office"
              },
              {
                "name": 'Pin Code',
                "code": "pincodeBulk",
                "active": false,
                "link": "/main/location-bulk-import/add-pincode-bulk",
                "icon": "pin_drop"
              },
              {
                "name": 'Location Priority',
                "code": "locationPriorityBulk",
                "active": false,
                "link": "/main/location-bulk-import/add-locationPriority-bulk",
                "icon": "place"
              },
              {
                "name": 'Location',
                "code": "locationBulk",
                "active": false,
                "link": "/main/location-bulk-import/add-location-bulk",
                "icon": "place"
              }
            ]
          },
        ]
      },
      {
        "name": 'PropertyDetails',
        "code": "propertyDetails",
        "active": false,
        "link": "/main",
        "icon": "apartments",
        "showSubMenu": false,
        "roles": ['ROLE_SUPER_ADMIN'],
        "subMenus": [
          {
            "name": 'PropertyType',
            "code": "PropertyType",
            "active": false,
            "link": "/main/propertytype",
            "icon": "domain"
          },

          {
            "name": 'BuildingType',
            "code": "BuildingType",
            "active": false,
            "link": "/main/buildingtype",
            "icon": "apartments"
          },
          {
            "name": 'NearByFacilities',
            "code": "NearByFacilities",
            "active": false,
            "link": "/main/nearbyfacilities",
            "icon": "landmark"
          },
          {
            "name": 'PropertyFacing',
            "code": "PropertyFacing",
            "active": false,
            "link": "/main/propertyfacing",
            "icon": "games"
          },
          {
            "name": 'BuildingFacing',
            "code": "BuildingFacing",
            "active": false,
            "link": "/main/buildingfacing",
            "icon": "directions_walk"
          },
          {
            "name": 'SellerType',
            "code": "SellerType",
            "active": false,
            "link": "/main/sellertype",
            "icon": "sell"
          },
          {
            "name": 'HouseType',
            "code": "HouseType",
            "active": false,
            "link": "/main/housetype",
            "icon": "domain"
          },
          {
            "name": 'LeaseDuration',
            "code": "LeastDuration",
            "active": false,
            "link": "/main/leastduration",
            "icon": "watch_later"
          },
          {
            "name": 'HouseModel',
            "code": "HouseModel",
            "active": false,
            "link": "/main/housemodel",
            "icon": "housetree"
          },
          {
            "name": 'BHK',
            "code": "Bhk",
            "active": false,
            "link": "/main/bhk",
            "icon": "apartment"
          },
          {
            "name": 'Amenities',
            "code": "Amenities",
            "active": false,
            "link": "/main/amenities",
            "icon": "nature_people"
          },
          {
            "name": 'Frontdoortype',
            "code": "Frontdoortype",
            "active": false,
            "link": "/main/frontdoortype",
            "icon": "door_front"
          },
          {
            "name": 'Dimensioninches',
            "code": "Dimensioninches",
            "active": false,
            "link": "/main/dimensioninches",
            "icon": "AreaUnitType"
          },
          {
            "name": 'Plotfacing',
            "code": "Plotfacing",
            "active": false,
            "link": "/main/plotfacing",
            "icon": "land_slide"
          },
          {
            "name": 'Doorfacing',
            "code": "Doorfacing",
            "active": false,
            "link": "/main/doorfacing",
            "icon": "door_front"
          },
          {
            "name": 'Linktype',
            "code": "Linktype",
            "active": false,
            "link": "/main/linktype",
            "icon": "linktype"
          },
          {
            "name": 'Planlist',
            "code": "Planlist",
            "active": false,
            "link": "/main/planlist",
            "icon": "store"
          },
          {
            "name": 'Kitchentype',
            "code": "Kitchentype",
            "active": false,
            "link": "/main/kitchentype",
            "icon": "kitchen-counter "
          },
          {
            "name": 'Flooringtype',
            "code": "Flooringtype",
            "active": false,
            "link": "/main/flooringtype",
            "icon": "border_all"
          },
          {
            "name": 'Waterfacility',
            "code": "Waterfacility",
            "active": false,
            "link": "/main/waterfacility",
            "icon": "water"
          },
          {
            "name": 'Electricity',
            "code": "Electricity",
            "active": false,
            "link": "/main/electricity",
            "icon": "lightbulb"
          },
          {
            "name": 'CurrentYield',
            "code": "CurrentYield",
            "active": false,
            "link": "/main/currentyield",
            "icon": "trending_up"
          },
          {
            "name": 'SoilType',
            "code": "SoilType",
            "active": false,
            "link": "/main/soiltype",
            "icon": "blur_on"
          },
          {
            "name": 'LandSuitableFor',
            "code": "LandSuitableFor",
            "active": false,
            "link": "/main/landSuitableFor",
            "icon": "blur_linear"
          },
          {
            "name": 'Boundary',
            "code": "Boundary",
            "active": false,
            "link": "/main/boundary",
            "icon": "holiday_village"
          },
          {
            "name": 'Approvedby',
            "code": "Approvedby",
            "active": false,
            "link": "/main/approvedby",
            "icon": "approval"
          },
          {
            "name": 'Yearlyincome',
            "code": "Yearlyincome",
            "active": false,
            "link": "/main/yearlyincome",
            "icon": "Yearlyincome"
          },
          {
            "name": 'Beds',
            "code": "Beds",
            "active": false,
            "link": "/main/beds",
            "icon": "bed"
          },
          {
            "name": 'Bath',
            "code": "Bath",
            "active": false,
            "link": "/main/bath",
            "icon": "bathroom"
          },
          {
            "name": 'Kitchen',
            "code": "Kitchen",
            "active": false,
            "link": "/main/kitchen",
            "icon": "kitchen"
          },
          {
            "name": 'Balcony',
            "code": "Balcony",
            "active": false,
            "link": "/main/balcony",
            "icon": "balcony"
          },
          {
            "name": 'RoadAccess',
            "code": "RoadAccess",
            "active": false,
            "link": "/main/roadAccess",
            "icon": "blur_on"
          },
          {
            "name": 'LivingRoom',
            "code": "LivingRoom",
            "active": false,
            "link": "/main/livingRoom",
            "icon": "livingRoom"
          },
          {
            "name": 'Parking',
            "code": "Parking",
            "active": false,
            "link": "/main/parking",
            "icon": "Parking"
          },
          {
            "name": 'TotalRoom',
            "code": "TotalRoom",
            "active": false,
            "link": "/main/totalRoom",
            "icon": "TotalRoom"
          },
          {
            "name": 'AreaUnitType',
            "code": "areaUnitType",
            "active": false,
            "link": "/main/areaunittype",
            "icon": "AreaUnitType"
          },
        ]
      },

      {
        "name": 'Settings',
        "code": "masterSettings",
        "active": false,
        "link": "/main",
        "icon": "settings",
        "showSubMenu": false,
        "roles": ['ROLE_SUPER_ADMIN'],
        "subMenus": [
          {
            "name": 'Role',
            "code": "role",
            "active": false,
            "link": "/main/role",
            "icon": "work_outline"
          },
          {
            "name": 'Age',
            "code": "age",
            "active": false,
            "link": "/main/loan/ages",
            "icon": "perm_identity"
          },


          {
            "name": 'Profession',
            "code": "profession",
            "active": false,
            "link": "/main/profession",
            "icon": "work_outline"
          },


          {
            "name": 'Contact Entry Type',
            "code": "contactEntryType",
            "active": false,
            "link": "/main/contactEntryType",
            "icon": " contact_mail"
          },
          {
            "name": 'Update Label Count',
            "code": "updateLabelCount",
            "active": false,
            "link": "/main/loan/edit-application-constants",
            "icon": "AddBox"
          },
          {
            "name": 'Price',
            "code": "price",
            "active": false,
            "link": "/main/price",
            "icon": "attach_money",
            "showSubMenu": false,
            "subMenus": [
              {
                "name": 'Crores',
                "priceType": 'CRORES',
                "code": "crores",
                "active": false,
                "link": "/main/price/crore",
                "icon": "attach_money"
              },
              {
                "name": 'Lakhs',
                "priceType": 'LAKHS',
                "code": "lakhs",
                "active": false,
                "link": "/main/price/lakh",
                "icon": "attach_money"
              },
              {
                "name": 'Thousands',
                "priceType": 'THOUSANDS',
                "code": "thousands",
                "active": false,
                "link": "/main/price/thousand",
                "icon": "attach_money"
              },
              {
                "name": 'Hundreds',
                "priceType": 'HUNDREDS',
                "code": "hundreds",
                "active": false,
                "link": "/main/price/hundred",
                "icon": "attach_money"
              },
            ]
          },
          {
            "name": 'Footer',
            "code": "footer",
            "active": false,
            "link": "/main/footer",
            "icon": "attach_money",
            "showSubMenu": false,
            "subMenus": [
              {
                "name": 'Privacy policy',
                "footerType": 'PRIVACY_POLICY',
                "code": "privacy-policy",
                "active": false,
                "link": "/main/footer/privacy-policy",
                "icon": "attach_money"
              },
              {
                "name": 'Terms of use',
                "footerType": 'TERMS_OF_USE',
                "code": "terms-of-use",
                "active": false,
                "link": "/main/footer/terms-of-use",
                "icon": "attach_money"
              },
              {
                "name": 'Refund Policy ',
                "footerType": 'REFUND_POLICY',
                "code": "refund-policy",
                "active": false,
                "link": "/main/footer/refund-policy",
                "icon": "attach_money"
              },
              {
                "name": 'Purpose',
                "code": "purpose",
                "active": false,
                "link": "main/footer/purpose",
                "icon": " call "
              },
              {
                "name": 'ContactMessage',
                "code": "footercontactlist",
                "active": false,
                "link": "main/footer/footercontactlist",
                "icon": " call "
              },

            ]
          },
          {
            "name": 'About Us',
            "code": "aboutUs",
            "active": false,
            "link": "/main/aboutUs",
            "icon": "loyalty",
            "showSubMenu": false,
            "subMenus": [
              {
                "name": 'Social Media',
                "code": "socialmedia",
                "active": false,
                "link": "main/aboutUs/aboutSocialMedia",
                "icon": "face"
              },
              {
                "name": 'Social Reviews',
                "code": "socialreviews",
                "active": false,
                "link": "main/aboutUs/aboutSocialReviews",
                "icon": "loyalty"
              },
              {
                "name": 'Professionals',
                "code": "professionals",
                "active": false,
                "link": "main/aboutUs/aboutProfessionals",
                "icon": "loyalty"
              },
              {
                "name": 'Employee Details',
                "code": "employeeDetails",
                "active": false,
                "link": "main/aboutUs/aboutEmployeeDetails",
                "icon": "loyalty"
              },
              {
                "name": 'Save Video Url',
                "code": "saveVideoUrl",
                "active": false,
                "link": "main/aboutUs/add-videourl",
                "icon": "loyalty"
              },
              {
                "name": 'Update Video Url',
                "code": "updateVideoUrl",
                "active": false,
                "link": "main/aboutUs/edit-videourl",
                "icon": "loyalty"
              }
            ]
          },


        ]
      },
      {
        "name": 'Loan',
        "code": "loan",
        "active": false,
        "link": "/main/loan",
        "icon": "account_balance",
        "roles": ['ROLE_SUPER_ADMIN'],
        "showSubMenu": false,
        "subMenus": [
          {
            "name": 'HousingLoan',
            "code": "housingLoan",
            "active": false,
            "link": "main/loan/housingLoan",
            "icon": "account_balance"
          },
          {
            "name": 'MortgageLoan',
            "code": "mortgageLoan",
            "active": false,
            "link": "/main/loan/mortgageLoan",
            "icon": "account_balance"
          },
          {
            "name": 'PersonalLoan',
            "code": "personalLoan",
            "active": false,
            "link": "/main/loan/personalLoan",
            "icon": "account_balance"
          },
          {
            "name": 'PrimaryBank',
            "code": "primarybank",
            "active": false,
            "link": "/main/loan/primarybank",
            "icon": "account_balance"
          },
          {
            "name": 'LoanInterest',
            "code": "loanInterest",
            "active": false,
            "link": "/main/loan/loanInterest",
            "icon": " not_listed_location"
          },
          {
            "name": 'IncomeTax',
            "code": "incomeTax",
            "active": false,
            "link": "/main/loan/incomeTax",
            "icon": "account_balance"
          },


          {
            "name": 'PersonJobExperience',
            "code": "salaryRange",
            "active": false,
            "link": "/main/loan/personJobExperience",
            "icon": "person"
          },
          {
            "name": 'SalaryRange',
            "code": "salaryRange",
            "active": false,
            "link": "/main/loan/salaryRange",
            "icon": "money"
          },
          {
            "name": 'Income',
            "code": "personIncome",
            "active": false,
            "link": "/main/loan/personIncome",
            "icon": "money"
          },
          {
            "name": 'Faq',
            "code": "faq",
            "active": false,
            "link": "/main/faq",
            "icon": "contact_support",
            "showSubMenu": false,
            "subMenus": [
              {
                "name": 'HousingFaq',
                "loanType": 'HOUSING_LOAN',
                "code": "housing loan",
                "active": false,
                "link": "/main/loan/faq",
                "icon": "contact_support"
              },
              {
                "name": 'MortageFaq',
                "loanType": 'MORTGAGE_LOAN',
                "code": "mortgage loan",
                "active": false,
                "link": "/main/loan/faq",
                "icon": "contact_support"
              },
              {
                "name": 'PersonalFaq',
                "loanType": 'PERSONAL_LOAN',
                "code": "personal loan",
                "active": false,
                "link": "/main/loan/faq",
                "icon": "contact_support"
              },
              {
                "name": 'OtherFaq',
                "loanType": 'OTHER_LOAN',
                "code": "other loan",
                "active": false,
                "link": "/main/loan/faq",
                "icon": "contact_support"
              }
            ]
          },
        ]
      },
      {
        "name": 'Tags',
        "code": "tags",
        "active": false,
        "link": "/main/tags",
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "tag",
        "subMenus": [
          {
            "name": 'Manage Tag',
            "code": "tag",
            "active": false,
            "link": "/main/tag",
            "icon": "tag"
          },
          {
            "name": 'Paid Tag',
            "code": "paidTag",
            "active": false,
            "link": "/main/tag/paid-tag",
            "icon": "tag"
          },
          {
            "name": 'Free Tag',
            "code": "freeTag",
            "active": false,
            "link": "/main/tag/free-tag",
            "icon": "tag"
          },
          {
            "name": 'Settings',
            "code": "tagSettings",
            "active": false,
            "link": "/main/tags",
            "icon": "settings",
            "showSubMenu": false,
            "subMenus": [
              {
                "name": 'Tax',
                "code": "tax",
                "active": false,
                "link": "/main/tag/settings/tax",
                "icon": "money"
              },
              {
                "name": 'Recommended Tags',
                "code": "recommendedTag",
                "active": false,
                "link": "/main/tag/settings/recommendedTag",
                "icon": "tag"
              }
            ]
          },
        ]
      },
      {
        "name": 'ProfessionList',
        "code": "professionList",
        "active": false,
        "link": "/main/professionList",
        "subMenus": [
          {
            "name": 'ProfessionPageList',
            "code": "professionPageList",
            "active": false,
            "link": "/main/professionPageList",
            "icon": "work_outline"
          },
          {
            "name": 'ProfessionPageService',
            "code": "professionPageService",
            "active": false,
            "link": "/main/professionPageService",
            "icon": "work_outline"
          },
          {
            "name": 'CallToAction',
            "code": "callToAction",
            "active": false,
            "link": "/main/callToAction",
            "icon": "work_outline"
          },

          // {
          //   "name": 'Tags',
          //   "code": "tags",
          //   "active": false,
          //   "link": "/main/tags",
          //   "icon": "tag",
          //   "showSubMenu": false,
          //   "subMenus": [
          //     {
          //       "name": 'Manage Tag',
          //       "code": "tag",
          //       "active": false,
          //       "link": "/main/tag",
          //       "icon": "tag"
          //     },
          //     {
          //       "name": 'Paid Tag',
          //       "code": "paidTag",
          //       "active": false,
          //       "link": "/main/tag/paid-tag",
          //       "icon": "tag"
          //     },
          //     {
          //       "name": 'Free Tag',
          //       "code": "freeTag",
          //       "active": false,
          //       "link": "/main/tag/free-tag",
          //       "icon": "tag"
          //     },
          //     {
          //       "name": 'Settings',
          //       "code": "tagSettings",
          //       "active": false,
          //       "link": "/main/tags",
          //       "icon": "settings",
          //       "showSubMenu": false,
          //       "subMenus": [
          //         {
          //           "name": 'Tax',
          //           "code": "tax",
          //           "active": false,
          //           "link": "/main/tag/settings/tax",
          //           "icon": "money"
          //         },
          //         {
          //           "name": 'Recommended Tags',
          //           "code": "recommendedTag",
          //           "active": false,
          //           "link": "/main/tag/settings/recommendedTag",
          //           "icon": "tag"
          //         }
          //       ]
          //     },
          //   ]
          // },
          {
            "name": 'Report',
            "code": "report",
            "active": false,
            "link": "/main/report",
            "icon": "report"
          },
          {
            "name": 'DeletedPost',
            "code": "deletedPost",
            "active": false,
            "link": "/main/deletedPost",
            "icon": "deletedPost"
          },
          {
            "name": 'ProfessionalLimit',
            "code": "professionalLimit",
            "active": false,
            "link": "/main/professionalLimit",
            "icon": "work_outline"
          },
          {
            "name": 'ProfessionalPageReport',
            "code": "professionalPageReport",
            "active": false,
            "link": "/main/professionalPageReport",
            "icon": "work_outline"
          },
          {
            "name": 'ProfessionalPostReport',
            "code": "professionalPostReport",
            "active": false,
            "link": "/main/professionalPostReport",
            "icon": "work_outline"
          },

        ],
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "view_comfy"
      },


      {
        "name": 'Property Message',
        "code": "usermessage",
        "active": true,
        "link": "/main/usermessage",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "message"
      },

      {
        "name": 'Register',
        "code": "register",
        "active": true,
        "link": "/main/register",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "app_registration"
      },

      {
        "name": 'ReplaceRole',
        "code": "replacerole",
        "active": true,
        "link": "/main/replacerole",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "app_registration"
      },

      {
        "name": 'ViewRegisterted',
        "code": "viewregisterted",
        "active": true,
        "link": "/main/viewregisterted",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "app_registration"
      },
      {
        "name": 'PromoCodeRegisterted',
        "code": "promocoderegisterted",
        "active": true,
        "link": "/main/promocoderegisterted",
        "subMenus": [],
        "roles": ['ROLE_SUPER_ADMIN'],
        "icon": "app_registration"
      },

    ]
  }

  public showHideSubMenu = (menuCode: any, showSubMenu: boolean) => {
    for (let idx = 0; idx < this.menus.length; idx++) {

      if (this.menus[idx].code == menuCode) {
        this.menus[idx]['showSubMenu'] = !showSubMenu;
        break;
      }
      if (this.menus[idx].subMenus.length != 0) {
        console.log('this.menus[idx].subMenus', this.menus[idx].subMenus);
        for (let i = 0; i < this.menus[idx].subMenus.length; i++) {
          if (this.menus[idx].subMenus[i].code == menuCode) {
            this.menus[idx].subMenus[i]['showSubMenu'] = !showSubMenu;
            break;
          }
          if (this.menus[idx].subMenus[i].subMenus) {
            for (let idxx = 0; idxx < this.menus[idx].subMenus[i].subMenus.length; idxx++) {
              if (this.menus[idx].subMenus[i].subMenus[idxx].code == menuCode) {
                this.menus[idx].subMenus[i].subMenus[idxx]['showSubMenu'] = !showSubMenu;
                break;
              }
            }
          }
        }
      }
    }
  }


  public getAllMenusBasedOnRoles = () => {
    let roles = this.storageService.getRoles();
    let role = this.storageService.getAccessToken();
    let menus: any[] = [];
    for (let m = 0; m < this.menus.length; m++) {
      let roleCodes = this.menus[m]['roles'];
      for (let r = 0; r < roleCodes.length; r++) {
        if (roleCodes[r] == roles.roleCode) {
          menus.push(this.menus[m]);
        }
      }

    }
    console.log('line no getMenus', menus);
    return menus;
  }
  public getMenus = () => {
    let menus = this.getAllMenusBasedOnRoles();
    console.log('line no getMenus', menus);
    return menus;
  }

  public setMenuActive = (menu: any) => {
    for (let idx = 0; idx < this.menus.length; idx++) {
      if (this.menus[idx].code == menu.code) {
        this.menus[idx]['active'] = true;
      } else {
        this.menus[idx]['active'] = false;
      }
    }
  }
}
