import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-professional',
  templateUrl: './my-professional.component.html',
  styleUrls: ['./my-professional.component.css']
})
export class MyProfessionalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
