<nav class="navbar navbar-expand-sm  navbar-dark" (scroll)="onscroll()" [ngClass]="navbarfixed?'fixed':'nofixed'">
  <div class="container">
    <button mat-icon-button [ngClass]="status ? 'success' : 'default'" (click)="toggleSidebar()">
      <mat-icon>menu</mat-icon>
    </button> 
    <div class="d-flex">
      <div>
        <label class="profile">{{name}}</label> <br>
        <label class="roleName">{{roleName}}</label>
      </div>
      <button class="pg" (click)="onLogOut()">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </div>
     
  </div>

</nav>