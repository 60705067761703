import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loading: boolean = false;

  constructor() { }

  setLoading(loading: boolean, url?:string) {
    if(url && !this.checkAutoRefresh(url)){
      return;
    }else{
      this.loading = loading;
    }
  }

  getLoading(): boolean {
    return this.loading;
  }

  checkAutoRefresh(url:string) : boolean{
    if(url.includes('fetchAllFileUploads')){ //add more
      return false;
    }else{
      return true;
    }
  }

}