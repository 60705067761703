import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SideNavMenuService } from './data/side-nav-menu.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
  animations: [
    trigger(
      'inAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.2s ease-out',
              style({ height: 90, opacity: 1 }))
          ]
        )
      ]
    ),
    trigger(
      'outAnimation',
      [
        transition(
          ':leave',
          [
            style({ height: 90, opacity: 1 }),
            animate('0.2s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class SidenavComponent implements OnInit {

  public menus: any[] = [];
  showFiller = false;
  arrow = false;
  activeItem: any = null;

  constructor(private sideNavMenuService: SideNavMenuService, private router: Router) {
    this.menus = this.sideNavMenuService.getMenus();
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     const activeRoute = event.urlAfterRedirects; 
    //       this.activeItem = this.menus.find(item => item.link == activeRoute);
    //   }
    // });
  }

  ngOnInit(): void {
    console.log('line no ngOnInit', this.menus);

  }

  showHideSubMenu = (menu: any) => {
    this.sideNavMenuService.setMenuActive(menu);
    this.sideNavMenuService.showHideSubMenu(menu.code, menu.showSubMenu);
  }

  routeMenu = (menu: any) => {
    // this.activeItem = menu;
    this.sideNavMenuService.setMenuActive(menu);
    this.router.navigate([menu.link], { queryParams: { priceType: menu.priceType, loanType: menu.loanType, footerType: menu.footerType, memberShipType: menu.memberShipType } });
  }
}
