import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage-service';
import { HttpService } from 'src/app/shared/services/http/http-service.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() toggleSidebarForMe: EventEmitter<any> = new EventEmitter();
  navbarfixed: boolean = false;
  status: any;
  public user: any;
  public name: any;
  public roleName: any;
  public roleCode: any;
  path: any;



  constructor(private router: Router, private httpService: HttpService,
    public snackbarService: SnackbarService, private storageService: StorageService) { }

  @HostListener('window:scroll', ['$event']) onscroll() {
    if (window.scrollY > 100) {
      this.navbarfixed = true;
    }
    else {
      this.navbarfixed = false;
    }
  }
  toggleSidebar() {
    this.status = !this.status;
    this.toggleSidebarForMe.emit();
  }
  ngOnInit(): void {
    this.user = this.storageService.getUserData();
    this.name = this.user.user.firstName;
    this.roleName = this.user.user.role.roleName;
    this.roleCode = this.user.user.role.roleCode;
    this.path = this.router.url;
  }

  onLogOut() {
    // this.storageService.clear();
    if(this.roleCode == 'ROLE_SUPER_ADMIN' ){
      this.router.navigate(['/super_admin']);
    }else{
      this.router.navigate(['/']);
    }
    
  }

}
