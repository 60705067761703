import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [  {
  path: '',
  pathMatch: 'full',
  component: LoginComponent,
},
{
  path: 'super_admin',
  pathMatch: 'full',
  component: LoginComponent,
  data: { role: 'ROLE_SUPER_ADMIN' }
},
{
  path: 'tele_caller',
  pathMatch: 'full',
  component: LoginComponent,
  data: { role: 'ROLE_TELE_CALLER' }
},
{
  path: 'main',
  loadChildren: () => import(`./main-layout/main-layout.module`).then(
    (module) => module.MainLayoutModule)
}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
